import React from 'react';
// import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

// import siteConfig from '../../config/website.config';
import MainWrapper from '../components/Wrappers/MakeWrapper';
// import MainSection from '../components/Sections/MainSection';
// import MoreCategories from '../components/Sections/MoreCategories';
import Main2 from '../components/Sections/Main2';
import LargeTemplateGrid from '../components/TemplateGrid/LargeTemplateGrid';
import ToolsGrid from '../components/Grid/ToolsGrid';
import SEO from '../components/SEO';
import Layout from '../components/layout';

function Home({ data }) {
  const { t, language } = useI18next();
  return (
    <Layout>
      <SEO
        t={t}
        slug="homePage"
        seo={data.strapiImagetovideoHomePage.SEO}
        lang={language}
      />
      <MainWrapper>
        <Main2 slug="homePage" t={t} />

        {/* <section
          className="brands divider mx-auto text-center mt-12"
          style={{ height: '188.556px', fontSize: '20px' }}
        >
          <p>Trusted by top brands in the business</p>
          <img
            alt="Brands"
            src="https://d1nc6vzg2bevln.cloudfront.net/google-optimize/partners.png"
          />
        </section> */}

        {data.strapiImagetovideoHomePage.Widgets &&
          data.strapiImagetovideoHomePage.Widgets.map((widget, index) => {
            if (widget.Tool) {
              return <ToolsGrid key={index} widget={widget} t={t} />;
            }

            if (widget.TemplateItem) {
              return <LargeTemplateGrid key={index} widget={widget} />;
            }

            return null;
          })}
        {/* <MoreCategories /> */}
      </MainWrapper>
    </Layout>
  );
}

export default Home;

export const pageQuery = graphql`
  query FrontPage {
    strapiImagetovideoHomePage {
      SEO {
        Description
        Keywords
        Title
        Hreflang {
          Lang
          Link
          id
        }
        id
      }
      Heading
      Widgets {
        SubHeading
        TemplateItem {
          Link
          Name
          id
          Thumbnail {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        Tool {
          Name
          Link
          id
          Thumbnail {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        id
      }
    }
  }
`;
